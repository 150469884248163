/**
 * Singular
 *
 * 01. Video Replacement
 * 02. Fluid Video
 * 03. Videos
 * 04. Google Maps
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function () {
    'use strict';

    /**
     * Video Replacement
     *
     * @global jQuery
     * @author Daniele De Nobili
     */
    (function () {
        $('.js-video').each(function () {
            var self = $(this),
                image = self.find('.js-videoimage'),
                video = self.find('.js-videoiframe');

            image.on('click', function () {
                var url = '';

                image.hide();

                if (video.data('source') === 'vimeo') {
                    url = 'https://player.vimeo.com/video/' + video.data('video-id') + '?autoplay=1&byline=0&badge=0&title=0&loop=1';
                } else {
                    url = 'https://www.youtube.com/embed/' + video.data('video-id') + '?showinfo=0&rel=0&autoplay=1&autohide=1&color=white';
                }

                var iframe = $('<iframe width="1200" height="675" src="' + url + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');

                iframe.attr('data-title', video.data('title'));

                video
                    .html(iframe)
                    .show();
            });
        });
    }());


    /**
     * Fluid Video
     *
     * @requires plugins/jquery.fitvids.js
     */
    $('iframe[src*="youtube"]').parent().fitVids();


    /**
     * Videos
     *
     * @requires plugins/slick.js
     */
    (function () {
        $('.js-infobox').slick({
            dots: false,
            arrows: true,
            draggable: true,
            touchThreshold: 100,
            infinite: false,
            speed: 500,
            cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
            prevArrow: '<button class="arrow arrow--prev"><svg><use xlink:href="' + templateUrl + '/images/svg/sprite.svg#icons--arrow-left"></use></svg></button>',
            nextArrow: '<button class="arrow arrow--next"><svg><use xlink:href="' + templateUrl + '/images/svg/sprite.svg#icons--arrow-right"></use></svg></button>',
            appendArrows: '.infobox__nav'
        });
    }());


    /**
     * Google Maps
     *
     * @summary Mappa personalizzata con https://snazzymaps.com/
     * @requires plugins/jquery.googlemaps.js
     */
    (function () {
        var maps = $('.js-gmap');

        if (maps.length) {
            window.onCookiePolicyAccepted(function(){
                var d = document,
                    t = 'script';

                function l(u) {
                    var j = d.createElement(t),
                        s = d.getElementsByTagName(t)[0];

                    j.src = u;
                    j.async = 1;
                    s.parentNode.insertBefore(j, s);
                }

                l('//maps.googleapis.com/maps/api/js?key=' + gApiKey + '&v=3.exp&callback=initialize');
            });

            window.initialize = function () {
                maps.googlemaps({
                    style: [
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e9e9e9"
                                },
                                {
                                    "lightness": 17
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 17
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 29
                                },
                                {
                                    "weight": 0.2
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 18
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 16
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                },
                                {
                                    "lightness": 21
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dedede"
                                },
                                {
                                    "lightness": 21
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 16
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "saturation": 36
                                },
                                {
                                    "color": "#333333"
                                },
                                {
                                    "lightness": 40
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f2f2f2"
                                },
                                {
                                    "lightness": 19
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#fefefe"
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#fefefe"
                                },
                                {
                                    "lightness": 17
                                },
                                {
                                    "weight": 1.2
                                }
                            ]
                        }
                    ]
                });
            };
        }
    }());

}());
