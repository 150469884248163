/**
 * Product
 *
 * 01. Image Zoom
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function () {
    'use strict';

    /**
     * Image Zoom
     *
     * @requires plugins/jquery.ez-plus.js
     */
    $('.js-productzoom').ezPlus({
        zoomType: "inner",
        imageCrossfade: true,
        borderSize: 0,
        easing: true,
        responsive: true,
        loadingIcon: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
    });

}());