/**
 * Global
 *
 * 01. SVG
 * 02. Fluid Video
 * 03. Google Maps
 * 04. Scrollbar
 *
 * @global jQuery
 * @author Matteo Casonato
 */


(function ($) {
	'use strict';

    /**
	 * SVG
	 *
	 * @requires libraries/svg4everybody.js
	 * @requires ie/svg4everybody.legacy.js
	 */
	svg4everybody();


    /**
     * Object-Fit Image
     *
     * @requires libraries/ofi.js
     */
    $(function () {
        objectFitImages();
    });


    /**
     * Scrollbar
     *
     * @requires plugins/smooth-scrollbar.js
     */
    Scrollbar.initAll({
        damping: 0.05,
        alwaysShowTracks: false,
        continuousScrolling: true,
        renderByPixels: false
    });

}(jQuery));