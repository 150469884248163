/**
 * Navigation
 *
 * @link https://github.com/CodyHouse/responsive-sidebar-navigation/
 * @global jQuery
 * @author Matteo Casonato
 *
 * @requires plugins/jquery.menu-aim.js
 */

jQuery(document).ready(function () {
    //cache DOM elements
    var header = $('.js-header'),
        headerNav = $('.js-headerNav'),
        headerTrigger = $('.js-headerTrigger'),
        headerCopyright = $('.js-headerCopyright');

    // Durante il ridimensionamento sposta il Copyright in base alle dimensioni della finestra
    var resizing = false;
    moveNavigation();
    $(window).on('resize', function () {
        if (!resizing) {
            (!window.requestAnimationFrame) ? setTimeout(moveNavigation, 300) : window.requestAnimationFrame(moveNavigation);
            resizing = true;
        }
    });

    // Mobile: Apre la barra quando l'utente fa click nell'Hamburger menu
    headerTrigger.on('click', function (event) {
        event.preventDefault();
        $([headerNav, headerTrigger]).toggleClass('nav-is-visible');
    });

    // Visualizza i sottomenù al click sulla voce
    $('.menu__item--parent > a').on('click', function (event) {
        var mq = checkMQ(),
            selectedItem = $(this);
        if (mq === 'mobile' || mq === 'tablet') {
            event.preventDefault();
            if (selectedItem.parent('li').hasClass('selected')) {
                selectedItem.parent('li').removeClass('selected');
            } else {
                headerNav.find('.menu__item--parent.selected').removeClass('selected');
                selectedItem.parent('li').addClass('selected');
            }
        }
    });

    $(document).on('click', function (event) {
        if (!$(event.target).is('.menu__item--parent a')) {
            headerNav.find('.menu__item--parent.selected').removeClass('selected');
        }
    });

    // Desktop: con jquery.menu-aim.js distinguere tra un utente che tenta di passare con il mouse su un elemento a discesa e provare a navigare nei contenuti di un sottomenu
    headerNav.children('ul').menuAim({
        activate: function (row) {
            $(row).addClass('hover');
        },
        deactivate: function (row) {
            $(row).removeClass('hover');
        },
        exitMenu: function () {
            headerNav.find('.hover').removeClass('hover');
            return true;
        },
        submenuSelector: ".menu__item--parent"
    });

    // Controlla se il dispositivo è Desktop o Mobile attraverso una verifica via css
    function checkMQ() {
        return window.getComputedStyle(document.querySelector('.js-header'), '::before').getPropertyValue('content').replace(/'/g, "").replace(/"/g, "");
    }


    function moveNavigation() {
        var mq = checkMQ();

        if (mq === 'mobile') {
            detachElements();
            headerCopyright.removeClass('is-hidden').appendTo(headerNav);
        } else if ((mq === 'tablet' || mq === 'desktop')) {
            detachElements();
            headerCopyright.insertAfter(header.find('.js-headerNav'));
        }
        checkSelected(mq);
        resizing = false;
    }

    function detachElements() {
        headerCopyright.detach();
    }

    // Desktop: rimuove la classe selezionata dagli elementi selezionati nella versione per dispositivi mobile/tablet
    function checkSelected(mq) {
        if (mq === 'desktop') $('.menu__item--parent.selected').removeClass('selected');
    }
});