/**
 * Slide
 *
 * @link https://tympanus.net/codrops/2018/01/31/outdoors-template/
 *
 * @requires plugins/imagesloaded.pkgd.js
 * @requires plugins/jquery.lettering.js
 * @requires plugins/anime.js
 *
 * Note: Ho convertito l'ECMAScript 6 in ECMAScript 5 con https://es6console.com/ per la compatibilità con IE11
 */

(function () {
    'use strict';

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    // From https://davidwalsh.name/javascript-debounce-function.
    var debounce = function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function later() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    // Window size.
    var win = {width: window.innerWidth, height: window.innerHeight};

    // some animation settings.
    var settings = {
        image: {duration: 900, delay: 0, easing: [0.8, 0, 0.2, 1]},
        title: {duration: 700, delay: 200, easing: [0.8, 0, 0.2, 1]},
        brand: {duration: 900, delay: 400, easing: 'easeOutExpo'},
        subtitle: {duration: 900, delay: 400, easing: 'easeOutExpo'},
        link: {duration: 900, delay: 400, easing: 'easeOutExpo'},
        pagination: {duration: 300, delay: 400, easing: 'easeInOutQuad'},
        navigationCtrls: {duration: 800, easing: [0.8, 0, 0.2, 1]}
    };

    var Entry = function () {
        function Entry(el) {
            _classCallCheck(this, Entry);

            this.DOM = {el: el};
            this.init();
        }

        _createClass(Entry, [{
            key: 'init',
            value: function init() {
                // DOM elements:

                // Title
                this.DOM.title = this.DOM.el.querySelector('.spot__content > .spot__title');
                $(this.DOM.title).lettering('words').children('span').lettering().each( function(){
                    $(this).addClass('word');
                });
                this.DOM.titleLetters = this.DOM.title.querySelectorAll('.word span');

                // Brand
                this.DOM.brand = this.DOM.el.querySelector('.spot__content > .spot__brand');

                // Subtitle
                this.DOM.subtitle = this.DOM.el.querySelector('.spot__content > .spot__subtitle');

                // Link
                this.DOM.link = this.DOM.el.querySelector('.spot__content > .spot__link');

                // Image
                this.DOM.image = this.DOM.el.querySelector('.spot__img > .spot__img-inner');
            }
        }, {
            key: 'show',
            value: function show(direction) {
                this.isHidden = false;
                return this.toggle(direction);
            }
        }, {
            key: 'hide',
            value: function hide(direction) {
                this.isHidden = true;
                return this.toggle(direction);
            }
        }, {
            key: 'toggle',
            value: function toggle(direction) {
                this.direction = direction;
                return Promise.all([
                    this.toggleTitle(),
                    this.toggleBrand(),
                    this.toggleSubtitle(),
                    this.toggleLink(),
                    this.toggleImage()
                ]);
            }
        }, {
            key: 'toggleTitle',
            value: function toggleTitle() {
                var _this = this;

                var delayEntry = this.isHidden === true ? 0 : 500;

                anime.remove(this.DOM.titleLetters);

                return anime({
                    targets: this.DOM.titleLetters,
                    duration: settings.title.duration,
                    delay: function delay(target, index) {
                        return index * 30 + settings.title.delay + delayEntry;
                    },
                    easing: settings.title.easing,
                    translateY: this.isHidden ? [0, this.direction === 'next' ? '-100%' : '100%'] : [this.direction === 'next' ? '100%' : '-100%', 0],
                    opacity: {
                        value: this.isHidden ? 0 : 1,
                        duration: 1000,
                        delay: function delay(target, index) {
                            return _this.isHidden ? settings.title.duration + settings.title.delay : settings.title.delay;
                        }
                    }
                }).finished;
            }
        }, {
            key: 'toggleFade',
            value: function toggleFade(dom, settings) {
                anime.remove(dom);
                return anime({
                    targets: dom,
                    duration: settings.duration,
                    delay: !this.isHidden ? settings.duration * 0.5 + settings.delay : settings.delay,
                    easing: settings.easing,
                    //translateY: this.isHidden ? [0, this.direction === 'next' ? '-10%' : '10%'] : [this.direction === 'next' ? '20%' : '-20%', 0],
                    opacity: this.isHidden ? 0 : 1
                }).finished;
            }
        },{
            key: 'toggleBrand',
            value: function toggleBrand() {
                return this.toggleFade(this.DOM.brand, settings.brand);
            }
        }, {
            key: 'toggleSubtitle',
            value: function toggleSubtitle() {
                return this.toggleFade(this.DOM.subtitle, settings.subtitle);
            }
        }, {
            key: 'toggleLink',
            value: function toggleLink() {
                return this.toggleFade(this.DOM.link, settings.link);
            }
        }, {
            key: 'toggleImage',
            value: function toggleImage() {
                this.DOM.image.style.transformOrigin = !this.isHidden ? '50% ' + (this.direction === 'next' ? 0 : 100) + '%' : '50% 50%';

                anime.remove(this.DOM.image);
                return anime({
                    targets: this.DOM.image,
                    duration: settings.image.duration,
                    delay: settings.image.delay,
                    easing: settings.image.easing,
                    translateY: this.isHidden ? ['0%', this.direction === 'next' ? '-100%' : '100%'] : [this.direction === 'next' ? '100%' : '-100%', '0%'],
                    scale: !this.isHidden ? [1.8, 1] : 1,
                    opacity: {
                        value: this.isHidden ? 0 : 1,
                        duration: 1,
                        delay: this.isHidden ? settings.image.duration + settings.image.delay : settings.image.delay
                    }
                }).finished;
            }
        }]);

        return Entry;
    }();

    var Slideshow = function () {
        function Slideshow(el) {
            _classCallCheck(this, Slideshow);

            this.DOM = {};
            this.DOM.el = el;
            this.init();
        }

        _createClass(Slideshow, [{
            key: 'init',
            value: function init() {
                // DOM elements.
                this.DOM.pagination = this.DOM.el.querySelector('.spotlight__index .spotlight__index-inner');
                this.DOM.navigation = this.DOM.el.querySelector('.spotlight__arrow');
                this.DOM.navigation.prevCtrl = this.DOM.navigation.querySelector('.arrow--prev');
                this.DOM.navigation.nextCtrl = this.DOM.navigation.querySelector('.arrow--next');
                this.DOM.entries = Array.from(this.DOM.el.querySelectorAll('.spot'), function (entry) {
                    return new Entry(entry);
                });
                this.entriesTotal = this.DOM.entries.length;
                this.currentPos = 0;

                this.layout();
                // Init/Bind events.
                this.initEvents();
            }
        }, {
            key: 'layout',
            value: function layout() {
                this.currentEntry = this.DOM.entries[this.currentPos];
            }
        }, {
            key: 'initEvents',
            value: function initEvents() {
                var _this = this;

                // Navigation
                this.onPrevClick = function () {
                    return _this.navigate('prev');
                };
                this.onNextClick = function () {
                    return _this.navigate('next');
                };
                this.DOM.navigation.prevCtrl.addEventListener('click', this.onPrevClick);
                this.DOM.navigation.nextCtrl.addEventListener('click', this.onNextClick);

                // Window resize
                this.onResize = function () {
                    win = {width: window.innerWidth, height: window.innerHeight};
                    _this.layout();
                };
                window.addEventListener('resize', debounce(function () {
                    return _this.onResize();
                }, 20));
            }
        }, {
            key: 'navigate',
            value: function navigate(direction) {
                if (this.isEntriesAnimating) return;
                this.isEntriesAnimating = true;
                // Store direction
                this.direction = direction;
                // Update currentPos
                var newPos = this.currentPos = this.direction === 'next' ? this.currentPos < this.entriesTotal - 1 ? this.currentPos + 1 : 0 : this.currentPos = this.currentPos > 0 ? this.currentPos - 1 : this.entriesTotal - 1;

                var newEntry = this.DOM.entries[newPos];

                this.update(newEntry);
            }
        }, {
            key: 'update',
            value: function update(newEntry) {
                var _this = this;

                var updateFn = function updateFn() {
                    // hide the current entry and show the next/previous one.
                    // when both updatePageNumber, hide and show are finished:
                    Promise.all([
                        _this.currentEntry.hide(_this.direction),
                        newEntry.show(_this.direction),
                        _this.updatePageNumber()
                    ]).then(function () {
                        _this.currentEntry.DOM.el.classList.remove('spot--current');
                        newEntry.DOM.el.classList.add('spot--current');
                        _this.currentEntry = newEntry;
                        _this.isEntriesAnimating = false;
                    });
                };

                updateFn();
            }
        }, {
            key: 'updatePageNumber',
            value: function updatePageNumber() {
                var _this = this;

                anime.remove(this.DOM.pagination);
                var halfway = false;
                return anime({
                    targets: this.DOM.pagination,
                    duration: settings.pagination.duration,
                    easing: 'easeInOutQuad',
                    translateY: [{
                        value: this.direction === 'next' ? '-100%' : '100%',
                        delay: settings.pagination.delay
                    }, {
                        value: [this.direction === 'next' ? '100%' : '-100%', '0%'],
                        delay: settings.pagination.duration
                    }],
                    opacity: [{value: 0, delay: settings.pagination.delay}, {
                        value: [0, 1],
                        delay: settings.pagination.duration
                    }],
                    update: function update(anime) {
                        if (anime.progress >= 50 && !halfway) {
                            halfway = true;
                            _this.DOM.pagination.innerHTML = '0' + (_this.currentPos + 1);
                        }
                    }
                }).finished;
            }
        }]);

        return Slideshow;
    }();

    // Preload all background the images in slide
    var container = $('.js-spotlight');

    if (!container.length) {
        return;
    }

    container.imagesLoaded( { background: '.spot__img-inner' }, function() {
        container.removeClass('loading');
        // Init
        new Slideshow(document.querySelector('.js-spotlight'));
    });
}());


/**
 * News Tilt Effects
 *
 * @see https://tympanus.net/Development/MorphingBackgroundShapes/
 *
 * @requires plugins/imagesloaded.pkgd.js
 * @requires plugins/anime.js
 */
(function () {
    'use strict';

    // Helper vars and functions.
    var extend = function extend(a, b) {
        for (var key in b) {
            if (b.hasOwnProperty(key)) {
                a[key] = b[key];
            }
        }
        return a;
    };

    // from http://www.quirksmode.org/js/events_properties.html#position
    var getMousePos = function getMousePos(ev) {
        var posx = 0;
        var posy = 0;
        if (!ev) ev = window.event;
        if (ev.pageX || ev.pageY) {
            posx = ev.pageX;
            posy = ev.pageY;
        } else if (ev.clientX || ev.clientY) {
            posx = ev.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            posy = ev.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }
        return {x: posx, y: posy};
    };

    var TiltObj = function TiltObj(el, options) {
        this.el = el;
        this.options = extend({}, this.options);
        extend(this.options, options);
        this.DOM = {};
        this.DOM.imgTop = this.el.querySelector('.objects-top');
        this.DOM.imgBottom = this.el.querySelector('.objects-bottom');
        this._initEvents();
    };

    TiltObj.prototype.options = {
        movement: {
            imgTop: {translation: {x: -10, y: -10}},
            imgBottom: {translation: {x: 25, y: 25}}
        }
    };

    TiltObj.prototype._initEvents = function () {
        var _this = this;

        this.mouseenterFn = function (ev) {
            anime.remove(_this.DOM.imgTop);
            anime.remove(_this.DOM.imgBottom);
        };

        this.mousemoveFn = function (ev) {
            requestAnimationFrame(function () {
                return _this._layout(ev);
            });
        };

        this.mouseleaveFn = function (ev) {
            requestAnimationFrame(function () {
                anime({
                    targets: [_this.DOM.imgTop, _this.DOM.imgBottom],
                    duration: 1500,
                    easing: 'easeOutElastic',
                    elasticity: 400,
                    translateX: 0,
                    translateY: 0
                });
            });
        };

        this.el.addEventListener('mousemove', this.mousemoveFn);
        this.el.addEventListener('mouseleave', this.mouseleaveFn);
        this.el.addEventListener('mouseenter', this.mouseenterFn);
    };

    TiltObj.prototype._layout = function (ev) {
        var mousepos = getMousePos(ev); // Mouse position relative to the document.
        var bounds = this.el.getBoundingClientRect();
        var relmousepos = {x: mousepos.x - bounds.left, y: mousepos.y - bounds.top}; // Mouse position relative to the main element (this.DOM.el).

        // Movement settings for the animatable elements.
        var t = {
            imgTop: this.options.movement.imgTop.translation,
            imgBottom: this.options.movement.imgBottom.translation
        };

        var transforms = {
            imgTop: {
                x: (-1 * t.imgTop.x - t.imgTop.x) / bounds.width * relmousepos.x + t.imgTop.x,
                y: (-1 * t.imgTop.y - t.imgTop.y) / bounds.height * relmousepos.y + t.imgTop.y
            },
            imgBottom: {
                x: (-1 * t.imgBottom.x - t.imgBottom.x) / bounds.width * relmousepos.x + t.imgBottom.x,
                y: (-1 * t.imgBottom.y - t.imgBottom.y) / bounds.height * relmousepos.y + t.imgBottom.y
            }
        };

        this.DOM.imgTop.style.WebkitTransform = this.DOM.imgTop.style.transform = 'translateX(' + transforms.imgTop.x + 'px) translateY(' + transforms.imgTop.y + 'px)';
        this.DOM.imgBottom.style.WebkitTransform = this.DOM.imgBottom.style.transform = 'translateX(' + transforms.imgBottom.x + 'px) translateY(' + transforms.imgBottom.y + 'px)';
    };

    // Preload Images
    var init = function init() {
        imagesLoaded(document.body, function () {
            Array.from(document.querySelectorAll('.news')).forEach(function (el) {
                return new TiltObj(el);
            });
        });
    };

    init();
})();


/**
 * News Stacked Cards
 *
 * @see https://codepen.io/prasannapegu/pen/JdyrZP
 */
(function ($) {
    'use strict';

    var container = $('.js-stack'),
        list = container.find('.stack__list'),
        cards = container.find('.stack__item'),
        next = container.find('.arrow--next'),
        prev = container.find('.arrow--prev'),
        anim = false;

    next.click(function (event) {
        event.preventDefault();
        if (anim) {
            return;
        }

        anim = true;

        // reset
        cards = list.find('.stack__item');

        var next = cards.last();

        cards.removeClass('stack__item--prev stack__item--next stack__item--current');
        next.addClass('stack__item--next');

        setTimeout(function () {
            next.removeClass('stack__item--next');
            list.prepend(next);

            list.find('.stack__item').last().addClass('stack__item--current');

            anim = false;
        }, 500);
    });

    prev.click(function (event) {
        event.preventDefault();
        if (anim) {
            return;
        }

        anim = true;

        // reset
        cards = list.find('.stack__item');

        var prev = cards.first();

        cards.removeClass('stack__item--prev stack__item--next stack__item--current');
        list.append(prev);
        prev.addClass('stack__item--prev');

        setTimeout(function () {
            prev.removeClass('stack__item--prev');

            list.find('.stack__item').last().addClass('stack__item--current');

            anim = false;
        }, 500);
    });

}(jQuery));
